import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import smartphoneImage from '../../assets/img/brand/iphone-mac.png';

const StyledContainer = styled(Container)`
  background-color: #ffffff;
  minHeight: 880px;
  color: black;
  padding: 50px 0;
`;

const Title = styled.h2`
  font-size: 3rem;
  fontFamily: sans-serif;
  color: black;
  marginBottom: 2rem;
  text-align: center;
`;

const Text = styled.h2`
  font-size: 1.5rem;
  padding: 0 10px;
  color: black;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 1.3rem; // Ligeiramente menor para tablets
  }

  @media (max-width: 480px) {
    font-size: 1.1rem; // Ainda menor para telefones
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  display: block; // Isso garante que a imagem seja um bloco e possa ser centralizada
  margin: 0 auto; // Centraliza a imagem horizontalmente
  margin-bottom: 2rem; // Espaço abaixo da imagem

  @media (max-width: 1024px) {
    width: 80%; // Reduz o tamanho da imagem em telas menores que 1024px
  }

  @media (max-width: 768px) {
    width: 70%; // Reduz ainda mais em tablets
  }

  @media (max-width: 480px) {
    width: 60%; // Bem menor em smartphones
  }
`;

const AboutSection = () => {
  return (
    <StyledContainer fluid>
      <Row>
        <Col className="text-center">
          <Title>Quem somos?</Title>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col lg="6" className="px-lg-5">
          <Image src={smartphoneImage} alt="Dispositivos Reflora" />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col lg="4" className="mr-lg-3">
          <Text>O Reflora surge como uma ferramenta essencial para agroflorestores que buscam otimizar seus sistemas de produção e gestão. Com uma interface intuitiva e recursos especializados, oferecemos uma abordagem abrangente para o planejamento e acompanhamento de sistemas agroflorestais,</Text>
        </Col>
        <Col lg="4">
          <Text>integrando informações sobre culturas agrícolas, árvores frutíferas e espécies florestais. Além de facilitar o processo de planejamento, a plataforma fornece dados e análises que auxiliam os usuários na tomada de decisões sustentáveis, promovendo a biodiversidade e a resiliência dos sistemas agroflorestais.</Text>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default AboutSection;
