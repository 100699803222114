import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import CustomToast from './Toast'; 
import { postFormData } from '../utils/api';

function Contact() {
  const [statusMessage, setStatusMessage] = useState('');
  const [toastVisible, setToastVisible] = useState(false);
  const [toastType, setToastType] = useState('success');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    const result = await postFormData('https://formspree.io/f/mnqrgdnw', formData);

    setToastType(result.success ? 'success' : 'error');
    setStatusMessage(result.message);

    setToastVisible(true);
    setTimeout(() => setToastVisible(false), 5000);

    if (result.success) {
      form.reset();
    }
  };

  const formStyle = {
    border: '1px solid #3B93D3', // Adiciona uma borda azul ao formulário
    padding: '20px', // Adiciona um pouco de padding para melhorar a aparência
    borderRadius: '5px', // Adiciona bordas arredondadas ao formulário
    backgroundColor: '#ffffff',
  };

  return (
    <Container className="d-flex flex-column justify-content-center ">
      <Row className="justify-content-center" >
        <Col md="8" lg="7" style={formStyle}>
        <h2 style={{ fontSize: '2rem', color: '#000000', textAlign: 'center' }}>Tem dúvidas sobre nossos planos?</h2>
          <p style={{ fontSize: '1rem', textAlign: 'center' }}>
            Entre em contato conosco para saber mais sobre as vantagens de cada plano e encontre a melhor opção para suas necessidades.
          </p>
          <Form onSubmit={handleSubmit} >
            <FormGroup>
              <Label for="name">
                Nome <span className="text-danger">*</span>
              </Label>
              <Input
                id="name"
                type="text"
                name="nome"
                placeholder="Digite seu nome"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">
                E-mail <span className="text-danger">*</span>
              </Label>
              <Input
                id="email"
                type="email"
                name="e-mail"
                placeholder="Digite seu melhor e-mail"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="mensagem">
                Mensagem <span className="text-danger">*</span>
              </Label>
              <Input
                id="mensagem"
                name="mensagem"
                type="textarea"
                placeholder="Digite sua mensagem"
                required
              />
            </FormGroup>
            <Button color="success" block type="submit">
              Enviar
            </Button>
          </Form>
        </Col>
      </Row>
      {toastVisible && (
        <CustomToast
          message={statusMessage}
          type={toastType}
          onClose={() => setToastVisible(false)}
        />
      )}
    </Container>
  );
}

export default Contact;