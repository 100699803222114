import React from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import { Check } from '@phosphor-icons/react';
import Contact from './Contact';

const Plans = () => {
  const sectionStyle = {
    backgroundColor: '#7A957E',
    padding: '3rem 0',
    color: 'black',
    fontFamily: 'sans-serif'
  };

  const tableStyle = {
    width: '80%',
    margin: '0rem auto',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  const thStyle = {
    backgroundColor: '#3B93D3',
    color: 'white',
    border: '0px solid #ddd',
    padding: '8px',
    fontSize: '15px',
    textAlign: 'center',
  };

  const tdStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    fontSize: '14px'
  };

  const trStyle = {
    backgroundColor: '#f2f2f2',
  };

  const titleStyle = {
    fontSize: '3rem',
    fontFamily: 'sans-serif',
    color: 'black',
    textAlign: 'center',
  };

  return (
    <Container fluid style={sectionStyle} id="planos">
      <Row className="justify-content-center">
        <Col lg="12" style={titleStyle}>
          <h2 style={{ fontSize: '3rem', color: '#000000' }}>Planos</h2>
          <p style={{ fontSize: '1.3rem' }}>
            Estamos aqui para ajudar! Se você precisa de mais informações sobre nossos planos, não hesite em nos contatar. 
            </p>
        </Col>
      </Row>
      <Row className="justify-content-center pb-5">
        <Col md="8" lg="9">
          <div className="table-responsive">
            <Table style={tableStyle} striped>
              <thead>
                <tr style={trStyle}>
                  <th style={thStyle}>Recurso</th>
                  <th style={thStyle}>Pacote Padrão</th>
                  <th style={thStyle}>Adesão</th>
                  <th style={thStyle}>Combo 1</th>
                  <th style={thStyle}>Combo 2</th>
                  <th style={thStyle}>Combo 3</th>
                </tr>
              </thead>
              <tbody>
                <tr style={trStyle}>
                  <td style={tdStyle}>Serviço de planejamento</td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                </tr>
                <tr style={trStyle}>
                  <td style={tdStyle}>Auxílio para compreender a plataforma</td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                </tr>
                <tr style={trStyle}>
                  <td style={tdStyle}>Acesso ao clube de assinantes</td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                </tr>
                <tr style={trStyle}>
                  <td style={tdStyle}>Consultoria especializada</td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                </tr>
                <tr style={trStyle}>
                  <td style={tdStyle}>Assessoria técnica</td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                </tr>
                <tr style={trStyle}>
                  <td style={tdStyle}>Georreferenciamento</td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}></td>
                  <td style={tdStyle}><Check color='#1D4ED8'/></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      {/* Contact Form */}
      <Contact />
    </Container>
  );
};

export default Plans;