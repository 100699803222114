export const postFormData = async (url, formData) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      });
  
      if (response.ok) {
        return { success: true, message: 'Sua mensagem foi enviada com sucesso!' };
      } else {
        const data = await response.json();
        if (data.errors) {
          return { success: false, message: data.errors.map(error => error.message).join(', ') };
        } else {
          return { success: false, message: 'Oops! Algo deu errado.' };
        }
      }
    } catch (error) {
      return { success: false, message: 'Oops! Houve um problema ao enviar seu formulário.' };
    }
  };