import React from 'react';
import HeaderLandingPage from '../components/Headers/HeaderLandingPage';
import Footer from 'components/Landing/Footer';
import { Container, Card, CardBody, CardImg, CardTitle, CardText } from 'reactstrap';
import { Eye } from '@phosphor-icons/react';
import bannerImage from '../assets/img/brand/info-card.png';
import './NewsDetail.css';

const InfoDetails = () => {
  const pageStyle = {
    boxSizing: 'border-box',
    width: '100vw',
    maxWidth: '100%',
    overflowX: 'hidden', 
  };

  const newsItem = {
    imageSrc: bannerImage,
    category: 'Agrofloresta',
    title: 'Agrofloresta: a chave para o futuro sustentável da agricultura',
    description: `
      A prática da agrofloresta combina árvores, plantas e agricultura, promovendo um ecossistema sustentável e produtivo. 
      Isso não só melhora a biodiversidade, mas também aumenta a resiliência das culturas às mudanças climáticas.
      Estudos mostram que a agrofloresta pode aumentar a produtividade agrícola em até 50%, enquanto reduz a necessidade 
      de fertilizantes químicos e pesticidas. Além disso, sistemas agroflorestais bem planejados ajudam na conservação da água 
      e na prevenção da erosão do solo.
      Agricultores em várias regiões do mundo estão adotando essa prática como uma solução viável para enfrentar os desafios 
      ambientais e econômicos atuais. Iniciativas como a Reflora estão liderando essa mudança, oferecendo ferramentas e conhecimentos 
      para implementar a agrofloresta de maneira eficaz.
    `,
    date: 'Maio 5, 2024',
    views: 87,
  };

  return (
    <div style={pageStyle}> 
      <HeaderLandingPage />
      <Container className="news-detail">
        <Card className="news-detail-card">
          <CardImg top src={newsItem.imageSrc} alt={newsItem.title} className="news-detail-img" />
          <CardBody className="news-detail-body">
            <div className="news-detail-category">{newsItem.category}</div>
            <CardTitle tag="h2" className="news-detail-title">{newsItem.title}</CardTitle>
            <CardText className="news-detail-text">
              {newsItem.description}
            </CardText>
            <div className="news-detail-meta">
              <span>{newsItem.date}</span>
              <span><Eye size={16} /> {newsItem.views}</span>
            </div>
          </CardBody>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default InfoDetails;
