import React from 'react';
import Banner from '../components/Info/Banner';
import HeaderLandingPage from '../components/Headers/HeaderLandingPage';
import Footer from 'components/Landing/Footer';
import News from 'components/Info/News';

const Info = () => {
  const pageStyle = {
    boxSizing: 'border-box',
    width: '100vw',
    maxWidth: '100%',
    overflowX: 'hidden' // Esconde qualquer conteúdo que ultrapasse horizontalmente
  };

  return (
    <div style={pageStyle}> {/* Aplicando estilo diretamente ao elemento raiz */}
      <HeaderLandingPage />  
      <Banner/>    
      <News/>
      <Footer/>      
    </div>
  );
};

export default Info;
