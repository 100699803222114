import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MapPin,Calculator,UsersThree,Handshake,Database,LightbulbFilament } from '@phosphor-icons/react'; 

const BenefitsSection = () => {
  const circleStyle = {
    width: '3.125rem',  // 50px
    height: '3.125rem', // 50px
    backgroundColor: '#3B93D3',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 1rem', // Centralizado horizontalmente, 1rem de margem abaixo
    color: 'white', // Cor do ícone dentro do círculo
    fontSize: '1.5rem', // Tamanho do ícone (ajuste conforme necessário)
  };

  const titleStyle = {
    fontSize: '3rem',
    fontFamily: 'sans-serif',
    color: 'black',
    textAlign: 'center',
  };

  const subtitleStyle = {
    fontSize: '1.3rem', // Subtítulos menores
  };

  const textStyle = {
    fontSize: '1rem', // Texto do corpo
  };
  const textSubTitleStyle = {
    fontSize: '1.3rem', // Texto do corpo
  };

  return (
    <Container  className="text-center" >
      <Row className="justify-content-center my-5">
        <Col lg="12" style={titleStyle}>
          <h2 style={titleStyle}>Benefícios</h2>
          <p style={textSubTitleStyle}>
          A plataforma contribui para o fortalecimento de economias locais, ao mesmo tempo que apoia a recuperação de ambientes degradados e gera autonomia das pessoas envolvidas.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <div style={circleStyle}>
            <MapPin /> 
          </div>
          <h3 style={subtitleStyle}>Identificar a melhor distribuição</h3>
          <p style={textStyle}>
          A plataforma irá propor a distribuição ideal, com base nos parâmetros iniciais.</p>
        </Col>
        <Col md="4">
          <div style={circleStyle}>
            <Calculator /> 
          </div>
          <h3 style={subtitleStyle}>Prever os custos e faturamento</h3>
          <p style={textStyle}>
          Conseguiremos identificar o custo inicial e o faturamento com base no modelo proposto.</p>
        </Col>
        <Col md="4">
          <div style={circleStyle}>
            <UsersThree /> 
          </div>
          <h3 style={subtitleStyle}>Comunidade</h3>
          <p style={textStyle}>
          Plataforma possui um ambiente de iteratividade para compartilhar experiências.</p>
        </Col>
        
      </Row>
      <Row>
        <Col md="4">
          <div style={circleStyle}>
            <Handshake /> 
          </div>
          <h3 style={subtitleStyle}>Contatos</h3>
          <p style={textStyle}>
          Com a plataforma conseguimos conectar pessoas de todo o mundo que buscam otimizar o uso dos espaços.</p>
        </Col>
        
        <Col md="4">
          <div style={circleStyle}>
            <Database /> 
          </div>
          <h3 style={subtitleStyle}>Base de dados</h3>
          <p style={textStyle}>
          A base é atualizada de forma recorrente, inserindo novas especies e aumentando o número de combinações.</p>
        </Col>
        <Col md="4">
          <div style={circleStyle}>
            <LightbulbFilament /> 
          </div>
          <h3 style={subtitleStyle}>Informação disponível</h3>
          <p style={textStyle}>
          Por meio da internet será possível consultar todas as informações contidas no sistema.</p>
        </Col>
      </Row>      
    </Container>
  );
};

export default BenefitsSection;
