import React from 'react';
import { Table, Progress } from 'reactstrap'; // Certifique-se de importar o componente Table do 'reactstrap' ou outra biblioteca de UI que você esteja usando

function TabelaDados({ dados, columns = 2 }) {
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Espécie</th>
          {(columns === 3 || columns === 4) && <th scope="col">Quantidade</th>}
          {columns !== 4 && <th scope="col">Estrato</th>}
          {columns !== 4 && <th scope="col">Participação (%)</th>}
        </tr>
      </thead>
      <tbody>
        {dados.map((item) => (
          <tr key={item.index}>
            <th scope="row">{item.nome}</th>
            {(columns === 3 || columns === 4) && <td>{item.count}</td>}
            {columns !== 4 && <td>{item.estrato}</td>}
            {columns !== 4 && <td>
                <div className="d-flex align-items-center">
                    <span className="mr-2">{item.percenty}%</span>
                    <div>
                        <Progress
                        max="100"
                        value={item.percenty}
                        barClassName="bg-gradient-success"
                        />
                    </div>
                </div>
            </td>}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default TabelaDados;
