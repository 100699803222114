import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Substitua estes caminhos pelas imagens corretas dos seus apoiadores
import cnpqLogo from '../../assets/img/partners/cnpq.png';
import impactosPositivosLogo from '../../assets/img/partners/impactospositivos.svg';
//import desafioAgroflorestalLogo from '../../assets/img/partners/desafioagroflorestal.png';
import bidaoCuboLogo from '../../assets/img/partners/bidaocubo.png';
import climateVenturesLogo from '../../assets/img/partners/climateventures.png';
import empreendeAmazoniaLogo from '../../assets/img/partners/empreendeamazonia.png';
import sinapsebioLogo from '../../assets/img/partners/sinapsebio.svg';

const Sponsors = () => {
  const sectionStyle = {
    backgroundColor: '#7A957E',
    padding: '3rem 0',
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'ease-out',
    arrows: false,
    centerMode: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 768, // Dispositivos médios
        settings: {
          slidesToShow: 1,
          centerPadding: '20px',
        },
      },
      {
        breakpoint: 480, // Dispositivos pequenos
        settings: {
          slidesToShow: 1,
          centerPadding: '10px',
        },
      },
    ],
  };

  return (
    <Container fluid style={sectionStyle} id='parceiros'>
      <Row>
        <Col className="text-center">
          <h2 style={{ fontSize: '3rem', color: 'black', fontFamily: 'sans-serif' }}>Apoiadores</h2>
        </Col>
      </Row>
      <Row className="justify-content-center my-4">
        <Col className="justify-content-center my-4">
          <Slider {...sliderSettings}>
            <div>
              <img src={cnpqLogo} alt="CNPq Logo" className="img-fluid" style={{ maxWidth: '60%', display: 'block', margin: '0 auto' }} />
            </div>
            <div>
              <img src={impactosPositivosLogo} alt="Impactos Positivos Logo" className="img-fluid" style={{ maxWidth: '60%', display: 'block', margin: '0 auto' }} />
            </div>
            <div>
              <img src={bidaoCuboLogo} alt="Bidão Cubo Logo" className="img-fluid" style={{ maxWidth: '60%', display: 'block', margin: '0 auto' }} />
            </div>
            <div>
              <img src={climateVenturesLogo} alt="Climate Ventures Logo" className="img-fluid" style={{ maxWidth: '60%', display: 'block', margin: '0 auto' }} />
            </div>
            <div>
              <img src={empreendeAmazoniaLogo} alt="Empreende Amazônia Logo" className="img-fluid" style={{ maxWidth: '60%', display: 'block', margin: '0 auto' }} />
            </div>
            <div>
              <img src={sinapsebioLogo} alt="Sinapse Bio Logo" className="img-fluid" style={{ maxWidth: '60%', display: 'block', margin: '0 auto' }} />
            </div>
          </Slider>
        </Col>
      </Row>
    </Container>
  );
};

export default Sponsors;