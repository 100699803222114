import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import solutionImage from '../../assets/img/brand/iphone-mac.png';

const Solution = () => {
  // Estilos para a seção
  const sectionStyle = {
    padding: '4rem 2rem',
    color: 'black',
    backgroundColor: '#ffffff',
    textAlign: 'center',
  };

  // Estilos para a imagem
  const imageStyle = {
    width: '100%',
    maxHeight: '400px',
    borderRadius: '8px', // Opcional, para bordas arredondadas
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Adiciona uma sombra leve para destacar
  };

  // Estilos para o texto
  const textStyle = {
    fontSize: '1.25rem',
    textAlign: 'left',
    color: 'black',
    padding: '0 10px',
    lineHeight: '1.6',
  };

  const titleStyle = {
    fontSize: '3rem',
    fontFamily: 'sans-serif',
    color: 'black',
    marginBottom: '2rem',
  };

  return (
    <Container fluid style={sectionStyle} id="solution">
      <Row className="justify-content-center">
        <h1 className="display-3" style={titleStyle}>A Solução</h1>
      </Row>

      {/* Linha contendo a imagem e o texto */}
      <Row className="align-items-center">
        <Col lg="6" md="12" style={textStyle}>
          O Reflora surge como uma solução abrangente, projetada para facilitar a transição de agricultores que buscam adotar práticas agrícolas sustentáveis e integradas à natureza. Esta plataforma pioneira não apenas apoia essa transição, mas também promove ativamente a expansão da agrobiodiversidade, fomentando um ambiente agrícola mais resiliente e ecologicamente equilibrado. O Reflora visa auxiliar agricultores a prosperarem em harmonia com o meio ambiente, ao mesmo tempo em que impulsiona a conservação da biodiversidade e a segurança alimentar.
        </Col>
        <Col lg="6" md="12" className="d-flex justify-content-center">
          <img
            src={solutionImage}
            alt="Solução"
            style={imageStyle}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Solution;
