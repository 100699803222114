import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { YoutubeLogo, FacebookLogo, InstagramLogo } from '@phosphor-icons/react';
import logo from '../../assets/img/brand/logo-reflora.png';

const Footer = () => {
  const footerStyle = {
    backgroundColor: 'white',
    color: 'black',
    padding: '2rem 0',
    borderTop: '3px solid #7A957E',
    width: '100vw', // Garante que o footer tenha a largura da viewport
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw', // Centraliza corretamente o footer
    marginRight: '-50vw'
  };

  const iconStyle = {
    fontSize: '2rem',
    margin: '0 0.5rem',
    color: '#7A957E',
  };

  const logoStyle = {
    maxHeight: '4rem',
    width: 'auto',
    marginBottom: '1rem',
  };

  return (
    <footer style={footerStyle}>
      <Container fluid={true}>
        <Row className="justify-content-between align-items-center">
          <Col sm="4" className="text-left">
            <img src={logo} alt="Reflora Logo" style={logoStyle} />
            <p>Siga nos nas redes sociais</p>
            <div>
              <YoutubeLogo style={iconStyle} />
              <FacebookLogo style={iconStyle} />
              <InstagramLogo style={iconStyle} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p>Reflora todos os direitos reservados 2024</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
