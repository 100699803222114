import React from 'react';
import { Card, CardBody } from 'reactstrap';
import bannerImage from '../../assets/img/brand/infobanner.png';

function Banner() {
  // Estilos adicionais para sombra de texto e fundo do card
  const textStyle = {
    color: 'white',
    textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)',
    fontFamily: 'sans-serif',
    fontSize: '2rem', // Tamanho menor para dispositivos móveis
  };

  const backgroundStyle = {
    backgroundImage: `url(${bannerImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '28.125rem',
    position: 'relative', // Permite posicionar a camada sobre a imagem
  };

  const overlayStyle = {
    backgroundColor: 'rgba(36, 51, 39, 0.8)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  return (
    <Card className="card-lift shadow border-0" style={{ minHeight: '28.125rem' }}>
      <div style={backgroundStyle}>
        <div style={overlayStyle}></div>
        <CardBody className="py-5 d-flex flex-column justify-content-center align-items-center text-center" style={{ minHeight: '28.125rem', position: 'relative' }}>
          <h1 style={{ ...textStyle, fontSize: '2.5rem' }}>Em breve, descubra informações sobre o Reflora</h1>
          <h1 style={{ ...textStyle, fontSize: '2.5rem' }}>e agroflorestas, promovendo práticas sustentáveis</h1>
          <h1 style={{ ...textStyle, fontSize: '2.5rem' }}>e conservação da biodiversidade.</h1>
        </CardBody>
      </div>
    </Card>
  );
}

export default Banner;
