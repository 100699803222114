import React from 'react';
import Banner from '../components/Landing/Banner';
import HeaderLandingPage from '../components/Headers/HeaderLandingPage';
import Mission from 'components/About/Mission';
import BenefitsSection from '../components/Landing/BenefitsSection';

import { Container, Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import Sponsors from 'components/Landing/Sponsors';
import Goals from 'components/Landing/Goals';
import ImageAgroflorestry from 'components/Landing/ImageAgroflorestry';
import Plans from 'components/Landing/Plans';
import Footer from 'components/Landing/Footer';

const LandingPage = () => {
  const pageStyle = {
    boxSizing: 'border-box',
    width: '100vw',
    maxWidth: '100%',
    overflowX: 'hidden' // Esconde qualquer conteúdo que ultrapasse horizontalmente
  };

  return (
    <div style={pageStyle}> {/* Aplicando estilo diretamente ao elemento raiz */}
      <HeaderLandingPage />
      <Banner />
      <Container className="py-5 text-center" style={{backgroundColor: 'white'}} fluid>
        <Row>
          <Col lg="12">
            <h1 className="display-3" style={{fontSize: '3rem',fontFamily: 'sans-serif',color: 'black'}}>Potencialize suas plantações</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6">
            <div className="info">
              <h1 style={{ color: '#116019',fontSize: '4rem', fontFamily: 'sans-serif' }}>
                <CountUp end={300} duration={2.75} prefix="+ " />
              </h1>
              <p style={{fontSize: '2rem',fontFamily: 'sans-serif',color: 'black'}}>espécies cadastradas no sistema</p>
            </div>
          </Col>
          <Col lg="4" md="6">
            <div className="info">
              <h1 style={{ color: '#116019',fontSize: '4rem', fontFamily: 'sans-serif'}}>
                <CountUp end={2000} duration={2.75} prefix="+ " formattingFn={(value) => `${Math.floor(value / 1000)} mil`}/>
              </h1>
              <p style={{fontSize: '2rem',fontFamily: 'sans-serif',color: 'black'}}>combinações possíveis</p>
            </div>
          </Col>
          <Col lg="4" md="6">
            <div className="info">
              <h1 style={{ color: '#116019',fontSize: '4rem', fontFamily: 'sans-serif' }}>
                <CountUp end={200} duration={2.75} prefix="+ " />
              </h1>
              <p style={{fontSize: '2rem',fontFamily: 'sans-serif',color: 'black'}}>espécies inseridas em breve</p>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{backgroundColor: 'white'}}> {/* Mantendo o fundo branco para todos os componentes */}
        <Mission/>
        <ImageAgroflorestry/>
        <BenefitsSection/>
        <Sponsors/>
        <Goals/>
        <Plans/>
        <Footer/>
      </div>
    </div>
  );
};

export default LandingPage;
