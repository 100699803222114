/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import { useParams, useHistory } from 'react-router-dom';
// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.js";
import TabelaDados from "variables/table.js";
import Header from "components/Headers/Header.js";
import axios from "axios";

import styles from '../layouts/Auth.module.css';

const Index = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [activeNav, setActiveNav] = useState(2);

  const [grafficExample1, setGrafficExample1] = useState(chartExample1);
  const [grafficExample2, setGrafficExample2] = useState(chartExample2);

  const [individuos, setIndividuos] = useState([]);
  const [avgIndividuos, setAvgIndividuos] = useState();
  const [avgProduction, setAvgProduction] = useState();
  const [sumIndividuos, setSumIndividuos] = useState();
  const [sumProduction, setSumProduction] = useState();

  if (!id) {
    history.push("/auth/begin");
  }

  useEffect(() => {
    //https://reflora.app.br:5001/api/v1/production/code/${id}

    //const fetchData = async () => {
    axios.get(`https://reflora.app.br:5001/api/v1/production/code/${id}`).then((response) => {

    
      if (response.data && response.status === 200) {
        const arrayGraffProcuction = response.data.arrayGraficoProdutividade;
        const arrayGraffOrdenado = response.data.arrayOrdenadoLimite6;
        
        setIndividuos(response.data.arrayIndividuos);
        setAvgIndividuos(response.data.mediaIndividuos);
        setAvgProduction(response.data.mediaProdutividade);
        setSumIndividuos(response.data.somaIndividuos);
        setSumProduction(response.data.somaProdutividade);

        // Convertendo o valores para o formato desejado para o chart
        const labelsGrafficProduction = arrayGraffProcuction.map(item => item.index);
        const dataGrafficProduction = arrayGraffProcuction.map(item => (item.data));
        const labelsGrafficOrdenado = arrayGraffOrdenado.map(item => item.index);
        const dataGrafficOrdenado = arrayGraffOrdenado.map(item => (item.data));

        // Alterando as labels da data1
        setGrafficExample1({
            labels: labelsGrafficProduction,
            datasets: [
              {
                label: "Produção",
                data: dataGrafficProduction
              }
            ]
        });

        setGrafficExample2({
          labels: labelsGrafficOrdenado,
          datasets: [
            {
              label: "Maiores Produções",
              data: dataGrafficOrdenado,
              maxBarThickness: 10
            }
          ]
        });

      }
    }).catch((error) => {
      // Handle error if necessary
      console.error(error);
    });
  }, [id]);


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };
  return (
    <>
      <Header avgInd = {avgIndividuos} avgProd = {avgProduction} sumInd = {sumIndividuos} sumProd = {sumProduction}  />
      {/* Page content */}
      <Container fluid className= {`mt--7 ${styles.headerContent}`}>
        <Row >
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Gráfico
                    </h6>
                    <h2 className="text-white mb-0">Rendimento</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                      <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Anual</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                {grafficExample1?.labels?.length > 0 ? (
                  <Line
                    data={grafficExample1}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                ) : ( <p>Carregando...</p> )}

                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Maiores Rendimentos</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  {grafficExample2?.labels?.length > 0 ? (               
                    <Bar
                      data={grafficExample2}
                      options={chartExample2.options}
                    />
                  ) : ( <p>Carregando...</p> )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Espécies</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Ver todos
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <TabelaDados dados={individuos} />
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Total de Indivíduos</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Ver todos
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <TabelaDados dados={individuos} columns = {4} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
