import React from 'react';
import { Toast, ToastBody, Button } from 'reactstrap';

const CustomToast = ({ message, type, onClose }) => {
  const toastStyle = {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
    zIndex: 1050,
  };

  const bodyStyle = {
    backgroundColor: type === 'success' ? '#28a745' : '#dc3545',
    color: 'white',
  };

  return (
    <div style={toastStyle}>
      <Toast>
        <ToastBody icon={type === 'success' ? 'success' : 'danger'} style={bodyStyle}>
        <div className="d-flex justify-content-between w-100">
            <Button close onClick={onClose} />
          </div>
          {message}
        </ToastBody>
      </Toast>
    </div>
  );
};

export default CustomToast;