import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { 
  Navbar, 
  NavbarBrand, 
  NavbarToggler, 
  Collapse, 
  Nav, 
  NavItem, 
  NavLink 
} from 'reactstrap';
//WhatsappLogo
import { InstagramLogo } from '@phosphor-icons/react';
import logo from '../../assets/img/brand/logo-reflora.png';

const HeaderLandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const handleNavLinkClick = (event) => {
    event.preventDefault();
    const targetId = event.target.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setIsOpen(false); // Fechar o menu collapse após o clique
  };

  useEffect(() => {
    const closeMenu = event => {
      if (isOpen && !event.target.closest('.navbar')) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, [isOpen]);

  return (
    <Navbar className="navbar-horizontal navbar-light bg-white" expand="lg" fluid>
      <NavbarBrand href="/landing" onClick={toggle}>
        <img src={logo} alt="RefLora Logo" style={{ height: '50px' }} />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className="me-auto">
          {/* Espaçamento à esquerda do logotipo */}
        </Nav>
        <Nav navbar className="justify-content-center flex-grow-1 pe-3">
          <NavItem>
            <Link to="/landing" className="nav-link">Início</Link>
          </NavItem>
          <NavItem>
            <NavLink href="#planos" onClick={handleNavLinkClick}>Planos</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#parceiros" onClick={handleNavLinkClick}>Apoiadores</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#equipe" onClick={handleNavLinkClick}>Equipe</NavLink>
          </NavItem>
          <NavItem>
              <NavLink href="/info">Informações</NavLink>
          </NavItem>
          <NavItem>
            <Link to="/about" className="nav-link">Sobre</Link>
          </NavItem>
        </Nav>
        <Nav navbar className="align-items-lg-center ml-lg-auto">
          <NavItem>
            {/*<NavLink href="link-to-whatsapp"><WhatsappLogo color="#000000" size={24} /></NavLink>*/}
          </NavItem>
          <NavItem>
            <NavLink href="https://www.instagram.com/refloraoficial" target="_blank" rel="noopener noreferrer"><InstagramLogo color="#000000" size={24} /></NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/registrar" className="nav-link">Registrar</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/entrar" className="btn btn-primary" style={{ backgroundColor: '#3B93D3', color: 'white' }}>Entrar</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default HeaderLandingPage;
