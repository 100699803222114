import React from 'react';
import { Card, CardBody, CardImg, CardTitle, CardText } from 'reactstrap';
import { Eye } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import './InfoCard.css'; // Importar arquivo de estilo CSS

const InfoCard = ({ imageSrc, category, title, description, date, views }) => {
  // Gerar um slug a partir do título para usar na URL
  const slug = title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

  return (
    <Link to={`/detalhes`} className="info-card-link">
      <Card className="info-card">
        <CardImg top src={imageSrc} alt={title} className="info-card-img" />
        <CardBody className="info-card-body">
          <div className="info-card-category">{category}</div>
          <CardTitle tag="h2" className="info-card-title">{title}</CardTitle>
          <CardText className="info-card-text">{description}</CardText>
          <div className="info-card-meta">
            <span>{date}</span>
            <span><Eye size={16} /> {views}</span>
          </div>
        </CardBody>
      </Card>
    </Link>
  );
};

export default InfoCard;
