import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Mission = () => {
  // Estilos para a seção
  const sectionStyle = {
    padding: '4rem 2rem',
    color: 'black',
    backgroundColor: '#7A957E',
    textAlign: 'center',
  };

  // Estilos para o vídeo incorporado
  const videoStyle = {
    width: '100%',
    height: '400px',
    paddingTop: '1rem',
    border: 'none',
    borderRadius: '8px', // Opcional, para bordas arredondadas
  };

  // Estilos para o texto
  const textStyle = {
    fontSize: '1.25rem',
    textAlign: 'left',
    color: 'black',
  };

  const titleStyle = {
    fontSize: '3rem',
    fontFamily: 'sans-serif',
    color: 'black',
  };

  return (
    <Container fluid style={sectionStyle} id="mission">
      <Row className="justify-content-center">
        <h1 className="display-3" style={titleStyle}>Desafio</h1>
      </Row>

      {/* Linha contendo o vídeo e o texto */}
      <Row className="align-items-center">
        <Col lg="6" md="12" style={textStyle}>
          Muitos agricultores e produtores rurais enfrentam dificuldades em gerir recursos hídricos de forma eficiente, além de lidarem com o desafio de transformar resíduos orgânicos e esterco em algo útil. Há também a necessidade de adotar práticas de cultivo que sejam ecologicamente sustentáveis, produtivas e rentáveis, sem acesso a informações e orientações adequadas para implementar sistemas agroflorestais eficazes.
        </Col>
        <Col lg="6" md="12" className="d-flex justify-content-center">
          <iframe
            src="https://player.vimeo.com/video/178212900" // URL para embutir o vídeo do Vimeo
            title="Vídeo Nossa Missão"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={videoStyle}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Mission;
