/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import styles from './Auth.module.css';

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import HeaderLandingPage from "../components/Headers/HeaderLandingPage.js"
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-neutral");
    return () => {
      document.body.classList.remove("bg-neutral");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <HeaderLandingPage className={styles.headerOverlay}/>
      <div className="main-content" ref={mainContent}>
        
        <div className={`header py-8 py-lg-9 ${styles.headerBackground}`}>
        <div className={styles.overlay}></div>
          <Container>
            <div className={`header-body text-center mb-7 ${styles.headerContent}`}>
              <Row className="justify-content-center">
                <Col lg="8" md="10">
                  <h1 className="text-white mt--6">Seja bem vindo!</h1>
                  <p className="text-white">
                  Reflora é uma ferramenta para auxiliar 
                  quem deseja praticar agricultura integrada à natureza, 
                  ao mesmo tempo em que favorece a expansão da agrobiodiversidade.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        
        {/* Page content */}
        <Container style={{ marginTop: '-12rem' }} className={styles.headerContent}>
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/" />
            </Switch>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
