import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const Begin = () => {
  const history = useHistory();
  const [safs, setSafs] = useState([]);
  const [biomassa, setBiomassa] = useState([]);
  const [graosAnuais, setGraosAnuais] = useState([]);
  const [forrageiras, setForrageiras] = useState([]);
  const [hortalicas, setHortalicas] = useState([]);
  const [image, setImage] = useState("");
  const [comprimentoCanteiro, setComprimentoCanteiro] = useState();
  const [distanciaCanteiro, setDistanciaCanteiro] = useState();

  const [selected, setSelected] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [key, optionValue] = value.split('|');

    const otherSelected = selected.filter(s => s.label !== optionValue);
    if (optionValue) {
      setSelected([...otherSelected, { label: optionValue, selectName: name, id: parseInt(key) }]);
    } else {
      setSelected(otherSelected);
    }
  };
  
  const removeItem = (item) => {
    const newSelected = selected.filter(s => s !== item);
    setSelected(newSelected);
  };

  useEffect(() => {
    //https://reflora.app.br:5001/api/v1/typesaf/safs
    axios.get("https://reflora.app.br:5001/api/v1/typesaf/safs").then((response) => {
      if (response.data && response.data.length > 0) {
        setSafs(response.data);
        setBiomassa(response.data[0]?.biomassa || []);
        setGraosAnuais(response.data[0]?.graosAnuais || []);
        setForrageiras(response.data[0]?.forrageiras || []);
        setHortalicas(response.data[0]?.hortalicas || []);
        setImage(response.data[0]?.image || "");
        setComprimentoCanteiro(response.data[0]?.comprimentoCanteiro || 0);
        setDistanciaCanteiro(response.data[0]?.distanciaCanteiro || 0);
      }
    }).catch((error) => {
      // Handle error if necessary
      console.error(error);
    });
  }, []);

  function atualizaEspecies(e) {
    setBiomassa(safs[e.target.value]?.biomassa || []);
    setGraosAnuais(safs[e.target.value]?.graosAnuais || []);
    setForrageiras(safs[e.target.value]?.forrageiras || []);
    setHortalicas(safs[e.target.value]?.hortalicas || []);
    setImage(safs[e.target.value]?.image || []);
    setComprimentoCanteiro(safs[e.target.value]?.comprimentoCanteiro || 0);
    setDistanciaCanteiro(safs[e.target.value]?.distanciaCanteiro || 0);
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Process the form data as needed
    const uniqueCode = uuidv4();
    const saf = {
      codigo: uniqueCode,
      largura: parseFloat(e.target["largura"].value),
      comprimento: parseFloat(e.target["comprimento"].value),
      user_id: 1, // Usuário padrão, será ajustado futuramente
    };

    const speciesIDs = selected.map(item => item.id);

    const filteredSpeciesIDs = speciesIDs.filter((value) => !isNaN(value));

    const formData = {
      safCode: uniqueCode,
      speciesIDs : filteredSpeciesIDs,
      comprimento: parseFloat(e.target["comprimento"].value) || 0,
      largura: parseFloat(e.target["largura"].value) || 0,
      comprimentoCanteiro: comprimentoCanteiro, 
      distanciaCanteiro: distanciaCanteiro
    };

    // Make the API POST request with the form data
    // https://reflora.app.br:5001/api/v1/saf
    // https://reflora.app.br:5001/api/v1/production
  axios.post("https://reflora.app.br:5001/api/v1/saf", saf)
  .then((response) => {
    if(response.status === 201) {
      axios.post("https://reflora.app.br:5001/api/v1/production", formData)
      .then((response) => {
        if(response.status === 201) {
          history.push(`/admin/index/${uniqueCode}`);
        } else {
          console.log("Erro na resposta do POST.");
        }
      
      })
      .catch((error) => {
        // Handle errors from the API
        console.error(error);
        // Perform any error handling if needed
      });
    } else {
      console.log("Erro na resposta do POST.");
    }
  })
  .catch((error) => {
    // Handle errors from the API
    console.error(error);
    // Perform any error handling if needed
  });
};

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-4">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>Inicie aqui</h2>
            </div>
            <Form role="form">
              <FormGroup className="mb-1">
                <label className="form-control-label" htmlFor="tipos-safs">
                  Selecione o tipo de sistema agroflorestal
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-collection" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Horta"
                    type="select"
                    id="tipos-safs"
                    onChange={atualizaEspecies}
                  >
                    {safs.map((safs, index) => (
                      <option key={safs.id} value={index}>
                        {safs.nome}
                      </option>
                    ))}
                  </Input>
                </InputGroup>
                <div className="safs-image">
                {image ? (               
                    <img
                      alt={`${image}`}
                      key={image}
                      src={process.env.PUBLIC_URL + image}
                    />
                  ) : ( <p>Carregando...</p> )}
                  
                </div>
              </FormGroup>
            </Form>
          </CardHeader>

          <CardBody className="px-lg-3 py-lg-4">
            <label className="form-control-label" htmlFor="tipos-safs">
              Selecione as espécies por função
            </label>
            <Form role="form" onSubmit={handleSubmit}>
              <div className="pl-lg-0 py-lg-2">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        title="Este estrato é composto por árvores que emergem acima das outras árvores do sistema agroflorestal"
                        htmlFor="input-biomassa"
                      >
                        Biomassa
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-ui-04" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" id="select-biomassa" name="select-biomassa" onChange={handleChange}>
                          <option key="" value="|" >Selecione...</option>
                          {biomassa.map((biomassa) =>
                            biomassa.especie ? (
                              <option key={biomassa.id} value= {`${biomassa.id}|${biomassa.especie}`}>
                                {biomassa.especie}
                              </option>
                            ) : null
                          )}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        title="Este estrato é composto por árvores de médio a grande porte"
                        htmlFor="select-forrageiras"
                      >
                        Forrageiras
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-ui-04" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" id="select-forrageiras" name="select-forrageiras" onChange={handleChange}>
                        <option key="" value="|" >Selecione...</option>
                          {forrageiras.map((forrageiras) =>
                            forrageiras.especie ? (
                              <option key={forrageiras.id} value= {`${forrageiras.id}|${forrageiras.especie}`}>
                                {forrageiras.especie}
                              </option>
                            ) : null
                          )}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        title="Essas árvores são importantes para complementar o sombreamento e para fornecer frutos, madeira e outros produtos úteis"
                        htmlFor="select-graosAnuais"
                      >
                        Grãos/Anuais
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-ui-04" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" id="select-graosAnuais" name="select-graosAnuais" onChange={handleChange}>
                        <option key="" value="|" >Selecione...</option>
                          {graosAnuais.map((graosAnuais) =>
                            graosAnuais.especie ? (
                              <option key={graosAnuais.id} value= {`${graosAnuais.id}|${graosAnuais.especie}`}>
                                {graosAnuais.especie}
                              </option>
                            ) : null
                          )}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        title="Essas plantas são importantes para fixar o solo, melhorar a fertilidade do solo e para fornecer produtos úteis"
                        htmlFor="select-hortalicas"
                      >
                        Hortaliças
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-ui-04" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" id="select-hortalicas" name="select-hortalicas" onChange={handleChange}>
                        <option key="" value="|" >Selecione...</option>
                          {hortalicas.map((hortalicas) =>
                            hortalicas.especie ? (
                              <option key={hortalicas.id} value= {`${hortalicas.id}|${hortalicas.especie}`}>
                                {hortalicas.especie}
                              </option>
                            ) : null
                          )}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              
              <div>
                <strong>Espécies selecionadas:</strong>
                <div>
                    {selected.map(item => (
                        <Badge className="mt-1"
                            key={item.label} 
                            color="primary"
                            onClick={() => removeItem(item)}
                            style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer', marginRight: '9px' }}
                        >
                            {item.label} <Button close size="sm" />
                        </Badge>
                    ))}
                </div>
            </div>

              <FormGroup className="pl-lg-0 py-lg-4">
                
                <Row>
                  <Col lg="6">
                  <label
                  className="form-control-label"
                  title="Comprimento do espaço para plantação"
                  htmlFor="text-area"
                >
                  Comprimento (m)
                </label>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-bold-up" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="comprimento" placeholder="Comprimento" type="number" />
                    </InputGroup>
                  </Col>
                    
                  <Col lg="6">
                  <label
                    className="form-control-label"
                    title="Largura do espaço para plantação"
                    htmlFor="largura"
                  >
                    Largura (m)
                  </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-bold-right" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="largura" placeholder="Largura" type="number" />
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  GERAR INFORMAÇÕES
                </Button>
              </div>
              <div className="text-center text-muted mb-4">
                <small>
                  Alguma dúvida? <b>Clique aqui</b>
                </small>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Begin;
