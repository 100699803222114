import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import ods11 from '../../assets/img/brand/ods11.jpg';
import ods13 from '../../assets/img/brand/ods13.jpg';

const Title = styled.h1`
  font-size: 3rem; // Tamanho padrão
  color: black;
  font-family: sans-serif;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.5rem; // Tamanho menor para tablets
  }

  @media (max-width: 480px) {
    font-size: 1.5rem; // Tamanho menor para telefones
  }
`;

const Section = styled(Container)`
  background-color: #ffffff;
  padding: 3rem 0;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  

  img {
    max-width: 60%;
    margin-right: 20px;
    margin-left: 20px;
  }
  @media (max-width: 768px) {
    max-width: 60%;
  }

  @media (max-width: 480px) {
    max-width: 60%;
  }
`;

const Goals = () => {
  return (
    <Section fluid>
      <Row>
        <Col>
          <Title>Estamos em sintonia com os</Title>
          <Title>objetivos de desenvolvimento sustentável</Title>
        </Col>
      </Row>
      <Row className="justify-content-center my-4">
        <ImageWrapper>
        {/* <Slider {...sliderSettings}> */}
          <img src={ods11} alt="Objetivo 11" className="img-fluid" />
          <img src={ods13} alt="Objetivo 13" className="img-fluid" />
          {/* Imagens e sliders */}
         {/* </Slider> */}
        </ImageWrapper>
      </Row>
    </Section>
  );
};

export default Goals;
