import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import bannerImage from '../../assets/img/background/tree-compress-500px.jpg';

function Banner() {
  // Estilos adicionais para sombra de texto e fundo do card
  const textStyle = {
    color: 'white',
    textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)', // Sombra preta para aumentar o contraste
    fontFamily: 'sans-serif', // Define a família de fonte para todos os textos
  };

  const backgroundStyle = {
    backgroundImage: `url(${bannerImage})`,
    backgroundRepeat: 'repeat', /* Faz a imagem se repetir */
    backgroundSize: '500px 500px', /* Define o tamanho da imagem de fundo */
    width: '100%',
    minHeight: '55vh', /* Ajuste conforme necessário */
    position: 'relative', // Para posicionar a camada sobre a imagem
  };

  const overlayStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.75)', // Sobreposição com cor desejada
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  return (
    <Card className="card-lift shadow border-0" style={{ minHeight: '20.125rem' }}>
      <div className="card-background" style={backgroundStyle}>
        {/* Sobreposição para aplicar a cor com opacidade */}
        <div style={overlayStyle}></div>

        {/* Conteúdo do Card */}
        <CardBody className="py-5 d-flex flex-column justify-content-center align-items-center text-center" style={{ minHeight: '20.125rem', position: 'relative' }}>
          <h1 style={{ ...textStyle, fontSize: '3.5rem' }}>Reflora, sua plataforma de</h1>
          <h1 style={{ ...textStyle, fontSize: '3.5rem' }}>sistemas agroflorestais</h1>
          <p style={{ ...textStyle, fontSize: '2rem', marginTop: '50px' }}>Contribuímos para espalhar agroflorestas pelo mundo</p>
          <div className="d-flex flex-wrap justify-content-center mt-3">
            <Link to="/auth/begin">
              <Button className="btn-icon btn-3" style={{ backgroundColor: '#155F2B', color: 'white', border: '0', fontSize: '1rem', padding: '10px 20px',  marginRight: '10px' }} type="button">
                  Demonstração
              </Button>
            </Link>
           {/*
            <Link to="#planos">
            <Button className="btn-icon btn-3" style={{ backgroundColor: '#155F2B', color: 'white', border: 'none', fontSize: '1rem', padding: '10px 20px' }} type="button">
                Nossos planos
            </Button>
            </Link>
            */}
          </div>
        </CardBody>
      </div>
    </Card>
  );
}

export default Banner;
