import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import LandingPage from "views/LandingPage.js";
import About from 'views/About'
import Info from "views/Info.js";
import InfoDetails from "views/InfoDeatils";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* Rota de Layout de Administração */}
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      
      {/* Rota de Layout de Autenticação */}
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      
      {/* Rota para a Landing Page */}
      <Route path="/landing" component={LandingPage} />

      {/* Rota direta para o componente Info sem layout */}
      <Route path="/info" component={Info} />
      {/* Rota direta para o componente Info sem layout */}

      <Route path="/detalhes" component={InfoDetails} />

      {/* Rota direta para o componente Info sem layout */}
      <Route path="/about" component={About} />

      {/* Redirecionamento padrão */}
      <Redirect from="/" to="/landing" />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
