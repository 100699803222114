/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

import { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

const SpecieRegister = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const enviarDadosParaAPI = async (dadosFormulario) => {
    try {
      //"https://reflora.app.br:5001/api/v1/specie",
      const response = await axios.post(
        "https://reflora.app.br:5001/api/v1/specie",
        dadosFormulario
      );
      console.log(response.data); // Exemplo de como lidar com a resposta da API
    } catch (error) {
      console.error(error);
    }
  };

  const [dadosFormulario, setDadosFormulario] = useState({
    nome: "",
    estrato: "",
    ocupacao: "",
    colheita: 0,
    espacamento_x: 0,
    espacamento_y: 0,
    produtividade_mono: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDadosFormulario((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Acionando API!");
    enviarDadosParaAPI(dadosFormulario);
    setShouldRedirect(true);
  };

  return (
    <>
      {shouldRedirect && <Redirect to="/auth/begin" />}
      {
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h3>Adicione as informações da Espécie</h3>
              </div>
              <Form role="form" onSubmit={handleSubmit}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-nome"
                  >
                    Nome da Espécie
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Espécie"
                      id="input-nome"
                      type="text"
                      name="nome"
                      value={dadosFormulario.nome}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="input-nome"
                  >
                    Estrato
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      name="estrato"
                      value={dadosFormulario.estrato}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      <option value="Emergente">Emergente</option>
                      <option value="Alto">Alto</option>
                      <option value="Médio">Médio</option>
                      <option value="Baixo">Baixo</option>
                    </Input>
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="input-nome"
                  >
                    Ocupação
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      name="ocupacao"
                      value={dadosFormulario.ocupacao}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      <option value="0.2">0.2</option>
                      <option value="0.4">0.4</option>
                      <option value="0.6">0.6</option>
                      <option value="0.8">0.8</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="input-nome"
                  >
                    Colheita
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Colheita"
                      type="number"
                      step="0.1"
                      name="colheita"
                      value={dadosFormulario.colheita}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="input-nome"
                  >
                    Espaçamento x
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      step="0.1"
                      name="espacamento_x"
                      value={dadosFormulario.espacamento_x}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="input-nome"
                  >
                    Espaçamento y
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Espaçamento x"
                      type="number"
                      step="0.1"
                      name="espacamento_y"
                      value={dadosFormulario.espacamento_y}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="input-nome"
                  >
                    Produtividade Monocultura
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      step="1"
                      type="number"
                      name="produtividade_mono"
                      value={dadosFormulario.produtividade_mono}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit">
                    Adicionar Espécie
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      }
    </>
  );
};

export default SpecieRegister;
