import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Lightning, TreasureChest, Users } from '@phosphor-icons/react';
import solutionImage from '../../assets/img/brand/tool.png';
import databaseImage from '../../assets/img/partners/database-forest.jpg';
import costImage from '../../assets/img/partners/cost-planning.jpg';
import communityImage from '../../assets/img/partners/community.jpg';

// Estilos para a seção inteira
const FullWidthSection = styled.div`
  background-color: #7A957E;
  width: 100vw;
  overflow-x: hidden;
`;

// Container estilizado
const StyledContainer = styled(Container)`
  padding: 4rem 2rem;
  border-radius: 12px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 3rem;
  font-family: sans-serif;
  color: black;
  margin-bottom: 2rem;
  text-align: center;
`;

const Text = styled.p`
  font-size: 1.2rem; // Tamanho reduzido
  color: black;
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const CardTitle = styled.h3`
  font-size: 1.4rem; // Tamanho ligeiramente reduzido
  color: black;
  text-align: left;
  margin-bottom: 0.5rem;
`;

const CustomCard = styled(Card)`
box-shadow: ${props => (props.selected ? '0 16px 32px rgba(0, 0, 0, 0.4)' : '0 4px 12px rgba(0, 0, 0, 0.1)')};
border-radius: 8px;
  max-height: 250px;
  max-width: 850px; // Limite máximo
  margin: 0 auto 1.5rem;
  cursor: pointer; // Mostra que o card é clicável
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  background-color: ${props => (props.selected ? '#e8f0fe' : 'white')}; // Cor de fundo ao selecionar
`;

const Image = styled.img`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 400px;
  object-fit: cover; // Ajusta a imagem para manter a proporção
`;

const Tool = () => {
  const [selectedCard, setSelectedCard] = useState('default');

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const images = {
    default: solutionImage,
    database: databaseImage,
    cost: costImage,
    community: communityImage,
  };

  const icons = {
    database: <Lightning size={24} />,
    cost: <TreasureChest size={24} />,
    community: <Users size={24} />,
  };

  return (
    <FullWidthSection>
      <StyledContainer fluid>
        <Row>
          <Col className="text-center">
            <Title>Ferramenta poderosa</Title>
            <Text>
              Utilização da tecnologia para prover uma previsão e planejamento para a agricultura agroflorestal.
            </Text>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center">
          {/* Cards de Informações */}
          <Col xs="12" sm="10" md="6">
            <CustomCard selected={selectedCard === 'database'} onClick={() => handleCardClick('database')}>
              <CardBody>
                <CardTitle>Banco de Dados Robusto {icons.database}</CardTitle>
                <Text>Uma base de dados com centenas de espécies cadastradas para Caatinga e Mata Atlântica.</Text>
              </CardBody>
            </CustomCard>
            <CustomCard selected={selectedCard === 'cost'} onClick={() => handleCardClick('cost')}>
              <CardBody>
                <CardTitle>Previsão de Custos e Distribuição {icons.cost}</CardTitle>
                <Text>Visão dos custos e da distribuição ideal, buscando aproveitar ao máximo os espaços.</Text>
              </CardBody>
            </CustomCard>
            <CustomCard selected={selectedCard === 'community'} onClick={() => handleCardClick('community')}>
              <CardBody>
                <CardTitle>Troca de Experiências {icons.community}</CardTitle>
                <Text>Ambiente para a troca de informação e criação da comunidade especializada em sistemas agroflorestais.</Text>
              </CardBody>
            </CustomCard>
          </Col>

          {/* Imagem de Ferramenta */}
          <Col xs="12" sm="10" md="6" className="text-center">
            <Image src={images[selectedCard]} alt="Ferramenta" />
          </Col>
        </Row>
      </StyledContainer>
    </FullWidthSection>
  );
};

export default Tool;
