import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import InfoCard from './InfoCard';
/*import Photo1 from '../../assets/img/brand/info-card.png';
import Photo2 from '../../assets/img/brand/info-card1.png';
import Photo3 from '../../assets/img/brand/info-card2.png';
import Photo4 from '../../assets/img/brand/info-card3.png';
import Photo5 from '../../assets/img/brand/info-card4.png';
import Photo6 from '../../assets/img/brand/info-card5.png';
*/
const News = () => {
  // Estilos para a seção de notícias
  const sectionStyle = {
    padding: '4rem 2rem', // Margem reduzida para telas menores
    color: 'black',
  };

  // Dados dos cartões de notícias
  
  const newsCards = [
    /***
    {
      imageSrc: Photo1,
      category: 'Reflora',
      title: 'Prêmio Otimista de Inovação: conheça as startups Reflora e Verde Acqua',
      description: 'Reflora é uma plataforma web de planejamento...',
      date: 'Abril 13, 2024',
      views: 111,
    },
    {
      imageSrc: Photo2,
      category: 'Agrofloresta',
      title: 'Agrofloresta: a chave para o futuro sustentável da agricultura',
      description: 'Agrofloresta é um sistema inovador...',
      date: 'Maio 5, 2024',
      views: 87,
    },
    {
      imageSrc: Photo3,
      category: 'Tecnologia Verde',
      title: 'Tecnologias verdes impulsionam a agricultura do futuro',
      description: 'Soluções tecnológicas e práticas sustentáveis...',
      date: 'Junho 10, 2024',
      views: 142,
    },
    {
      imageSrc: Photo4,
      category: 'Conservação',
      title: 'Conservação da biodiversidade: um compromisso global',
      description: 'Estratégias para promover a conservação...',
      date: 'Julho 20, 2024',
      views: 98,
    },
    {
      imageSrc: Photo5,
      category: 'Inovação',
      title: 'Inovações no mercado de produtos orgânicos',
      description: 'O mercado de produtos orgânicos está em crescimento...',
      date: 'Agosto 15, 2024',
      views: 123,
    },
    {
      imageSrc: Photo6,
      category: 'Sustentabilidade',
      title: 'Práticas sustentáveis em pequenas propriedades agrícolas',
      description: 'Dicas e práticas para melhorar a sustentabilidade...',
      date: 'Setembro 5, 2024',
      views: 76,
    },
    ***/
  ];

  return (
    <Container fluid style={sectionStyle} id="news">      
      <Row className="justify-content-center">
        {newsCards.map((news, index) => (
          <Col xs="12" sm="6" md="4" key={index} className="mb-4">
            <InfoCard {...news} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default News;
